exports.components = {
  "component---src-pages-2024-js": () => import("./../../../src/pages/2024.js" /* webpackChunkName: "component---src-pages-2024-js" */),
  "component---src-pages-3-d-js": () => import("./../../../src/pages/3d.js" /* webpackChunkName: "component---src-pages-3-d-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-snippets-js": () => import("./../../../src/pages/snippets.js" /* webpackChunkName: "component---src-pages-snippets-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

